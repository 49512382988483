<template>
<dash-page-new
    :title="$t('MobileFeed')"
    :subtitle="$t('MobileApps')"
    :root="$t('Dashboard')"
    :root-location="businessDashLink('')"
>

  <ws-text-field
      width="350"
      class="mx-auto"
      v-model="text"
      area
  />
  <div style="width: 350px" class="mx-auto mb-5">
    <ws-button
        class="mt-2"
        @click="sendMessage"
        label="Send"
        left-icon="mdi-send"
        :loading="LOADING"
        :disabled="!text"
        block
    />
    <image-param-picker
        v-model="image"
    />
  </div>


  <mobile-news-card
      v-for="item in feed" :key="item.uid"
      :item="item"
      class="mb-5 mx-auto"
  />




</dash-page-new>
</template>


<script>
import mobileNewsCard from "@modules/mobileapps/components/news/mobileNewsCard.vue";
import {mapActions} from "vuex";
import ImageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker.vue";
export default {
  name: "DashMobileAppsNews",
  components : {
    ImageParamPicker,
    mobileNewsCard
  },
  data() {
    return {
      text : '',
      feed : [],
      image : null
    }
  },
  methods : {
    ...mapActions('mobileapps' , [
        'GET_MOBILE_FEED',
        'ADD_MOBILE_FEED_POST'
    ]),

    async sendMessage() {

      let data = {
        content : this.text
      }

      if (this.image) {
        data.poster = {
          type : 'image',
          url : this.image
        }
      }

      let result = await this.ADD_MOBILE_FEED_POST(data)
      if (!result) {
        return
      }
      this.text = null
      this.feed.unshift(result)
    },

    async getFeed() {
      this.feed = (await this.GET_MOBILE_FEED()).items
    }
  },
  mounted() {
    this.getFeed()
  }
}
</script>

<style scoped>

</style>